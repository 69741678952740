import React, { useState, useEffect } from 'react';
import { DataStore } from '@aws-amplify/datastore';
import { Driver, DriverCar } from '../models';

export default (userName, isDriver) => {
  const [driverData, setDriverData] = useState(null)
  const [driverCars, setDriverCars] = useState(null)
  console.log('seeking',isDriver,userName)
  
  useEffect(() => {        	
    let subscription = DataStore.observeQuery(
        Driver, 
        d => d.cognito_id("eq", userName)
    ).subscribe( async snapshot => {
        const { items } = snapshot; 
        if (items.length) { 
			const driver_data = items[0]
			const driver_cars = (await DataStore.query(DriverCar))
			.filter(cd => cd.driver.id === driver_data.id)
			.map(cd => cd.car)            

			setDriverCars(driver_cars)            
			setDriverData(driver_data)
			console.log("driver data acquired",driver_data,driver_cars)      
        }
    })
    console.log("driver data subscribed")
    return () => {        
		subscription.unsubscribe()
		console.log("driver data unsubscribed")
    }
  }, [userName, isDriver])


  if (driverData === null) {
    return false;
  }
  return {data:driverData,cars:driverCars};
}