import React, { useState, useEffect } from 'react';
import { DataStore } from '@aws-amplify/datastore';
import { Driverstatus, Driver } from '../models';

export default (isManager) => {
  const [pendingDrivers, setPendingDrivers] = useState([])
  useEffect(() => {
        
    if (isManager) {
      let subscription = DataStore.observeQuery(
          Driver, 
          d => d.status("eq", Driverstatus.PENDING)
        ).subscribe(snapshot => {
          const { items } = snapshot; 
          if (items.length) {  
            setPendingDrivers(items)
            console.log("pendingDrivers acquired",items)
          } 
        })
      console.log("pendingDrivers subscribed")
      return () => {        
        subscription.unsubscribe()
        console.log("pendingDrivers unsubscribed")
      }
    }                       
  }, [isManager])  
  
  return pendingDrivers;
}