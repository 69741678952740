
import React, { useState, useEffect, useCallback } from 'react';
import { useSearchParams } from "react-router-dom"
import useUpcomingWeek from '../hooks/useUpcomingWeek'
import useCarReservations from '../hooks/useCarReservations'
import useDriverReservations from '../hooks/useDriverReservations'
import useGoogle from '../hooks/useGoogle'

import {
	CardTable,
	ModalContent,
  openModal,
	Button,
	openSpinner,
	closeSpinner
} from "@paytheory/pay-theory-ui";

import DaySquare from './DaySquare';
import CheckoutForm from './CheckoutForm';
import Select from './Select';
import { DataStore } from '@aws-amplify/datastore';
import { Reservation, Reservationstatus, Shift } from '../models';

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import { Auth } from 'aws-amplify';

const MORNING = "morning"
const AFTERNOON = "afternoon"
const EVENING = "evening"
const NIGHT = "night"

const SUCCEEDED = "succeeded"

const SHIFT_HOURS = {
  MORNING: {
    START: "05:00:00",
    END: "10:00:00"
  },
  AFTERNOON: {
    START: "11:00:00",
    END: "16:00:00"
  },
  EVENING: {
    START: "17:00:00",
    END: "22:00:00"
  },
  NIGHT: {
    START: "23:00:00",
    END: "04:00:00"
  }
}

const MONDAY = "Monday"
const TUESDAY = "Tuesday"
const WEDNESDAY = "Wednesday"
const THURSDAY = "Thursday"
const FRIDAY = "Friday"
const SATURDAY = "Saturday"
const SUNDAY = "Sunday"

const PREPAYMENT = {
  0: {
    minimum: 10,
    rate: 16,
    miles: 0
  },
  100: {
    minimum: 20,
    rate: 15,
    miles: 100
  },
  500: {
    minimum: 30,
    rate: 14,
    miles: 500
  },
  1000: {
    minimum: 40,
    rate: 13,
    miles: 1000
  }
}

const daily = [SUNDAY,MONDAY,TUESDAY,WEDNESDAY,THURSDAY,FRIDAY,SATURDAY]


const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

const findPrepaymentOptions = (reservedHours) => {
    if (reservedHours >= 40) {                    
      return [PREPAYMENT[1000],PREPAYMENT[500],PREPAYMENT[100],PREPAYMENT[0]]
    }
    if (reservedHours >= 30) {                    
      return [PREPAYMENT[500],PREPAYMENT[100],PREPAYMENT[0]]
    }
    if (reservedHours >= 20) {                    
      return [PREPAYMENT[100],PREPAYMENT[0]]
    }
    return [PREPAYMENT[0]]
}  



const ReservationScheduler = ({driver, cars, successCallback}) => {

  const { googleReady, googleAuth, isAuthorized } = useGoogle()

  const [readyForReservations, setReadyForReservations] = useState(false)
  const [readyForReservationEvents, setReadyForReservationEvents] = useState(false)

  const { upcomingSchedule, endDate } = useUpcomingWeek()

  const [chosenCar, setChosenCar] = useState("")

  const carReservations = useCarReservations(chosenCar,endDate,upcomingSchedule)

  const [searchParams, setSearchParams] = useSearchParams()

  const { 
    pendingReservations, 
    paidReservations, 
    unpaidMiles 
  } = useDriverReservations(
    driver, 
    endDate, 
    upcomingSchedule, 
    readyForReservations)
  
  

  const [driverPendingHours, setDriverPendingHours] = useState(0)
	
	const [driverReservedHours, setDriverReservedHours] = useState(0)
  const [driverHasReserved, setDriverHasReserved] = useState(0)  
  const [prepaymentOptions, setPrepaymentOptions] = useState([])
  const [paymentStatus, setPaymentStatus] = useState()

  const [redirect_status, ] = useState(searchParams.get("redirect_status"))  
  const [payment_intent, ] = useState(searchParams.get("payment_intent"))
  const [payment_intent_client_secret, ] = useState(searchParams.get("payment_intent_client_secret"))

  const createCalendarReservations = useCallback(async()=>{
    const events = []
    

    for (let i = 0; i < paidReservations.length; i++) {
      try {
        const car = cars.filter(car => car.id === paidReservations[i].carID)[0]
        const calendarId = car.calendar_resource
        
        const reservation = paidReservations[i]        
        
        const shiftTimeStart = SHIFT_HOURS[reservation.shift].START
        const shiftTimeEnd = SHIFT_HOURS[reservation.shift.toUpperCase()].END
        
        const shiftDateStart = reservation.date.replace(/[\D]/g,"-")
        let shiftDateEnd = reservation.date.replace(/[\D]/g,"-")
        if (reservation.shift === Shift.NIGHT) {
          const startDate = new Date(reservation.date)
          const endDate = new Date(startDate.valueOf() + 1000*3600*24)
          
          shiftDateEnd = endDate.toISOString().split('T')[0].replace(/[\D]/g,"-")
        }
        
        const resource = {
            summary: 'Carpe Works Reservation',
            description: `${driver.given_name} ${driver.family_name}`,
            start: {
              dateTime: `${shiftDateStart}T${shiftTimeStart}`,
              timeZone: 'America/New_York'
            },
            end: {
              dateTime: `${shiftDateEnd}T${shiftTimeEnd}`,
              timeZone: 'America/New_York'
            },
            attendees: [{email: driver.email },{email:"pan@onomatic.com"}]
        }    

        console.log("scheduling", resource, calendarId)
        
        const scheduled = await window.gapi.client.calendar.events.insert({ calendarId,resource })

        console.log("scheduled", scheduled)        
      } catch (err) {
        console.error(err)
      }
      
    }
    setIsPaying(false)
    return true
  },[paidReservations,cars])  

  useEffect(()=>{
    if (payment_intent_client_secret && googleReady) {
      stripePromise.then(stripe=>{
        return stripe.retrievePaymentIntent(payment_intent_client_secret)
      })    
      .then(result => {
        console.log("payment result",result)
        
        setPaymentStatus(result.paymentIntent.status)
        
        return true
      })
      .then(reserved=>{
        
        return setSearchParams({})
      })
    } else {
      if (payment_intent_client_secret && googleAuth) {
        googleAuth.signIn()
      }
      console.log("payment intent not ready", payment_intent_client_secret, googleReady)
    }

  },[payment_intent_client_secret, googleAuth, googleReady])

  useEffect(()=>{
    setDriverHasReserved(pendingReservations.length + paidReservations.length)
  },[pendingReservations,paidReservations])

  useEffect(()=>{
    setDriverReservedHours(paidReservations.length*5)
  },[paidReservations])  

  useEffect(()=>{
    setDriverPendingHours(pendingReservations.length*5)    
  },[pendingReservations])  

  useEffect(()=>{
      
      setReadyForReservationEvents(paymentStatus === SUCCEEDED && googleReady)  


  },[paymentStatus, googleReady])

  useEffect(()=>{
    (async()=>{
      console.log('readyForReservationEvents',readyForReservationEvents,paidReservations.length,window.localStorage.getItem('pending'))
      if (readyForReservationEvents && paidReservations.length.toString() === window.localStorage.getItem('pending') ) {
        window.localStorage.removeItem('pending')
        await createCalendarReservations()
        
        console.log("reservations scheduled")        
      }      
    })()
  },[readyForReservationEvents, paidReservations, createCalendarReservations])  

  useEffect(()=>{
    const prepayOptions = findPrepaymentOptions(driverPendingHours)
    console.log('prepay available for',driverPendingHours,prepayOptions)
    setPrepaymentOptions(prepayOptions)
  },[driverPendingHours])    
  
	const [day, setDay] = useState("")
	const [shift, setShift] = useState("")
	const [prepayRequest, setPrepayRequest] = useState("")
  const [paymentIntent, setPaymentIntent] = useState()
  const [isPaying, setIsPaying] = useState(false)  

  


  const onCheckout = useCallback(()=>{
    (async()=> {
      
      openSpinner()
      fetch(`${process.env.REACT_APP_API_ENDPOINT}/intent`, {
        method: "POST",
        headers: { 
          Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
          "Content-Type": "application/json" 
        },
        body: JSON.stringify({ driver: driver.id, prepay_request: PREPAYMENT[prepayRequest?prepayRequest:0].miles }),
      })
        .then((res) => res.json())
        .then((data) => setPaymentIntent(data));                      
    })()
  }, [driver, prepayRequest])

  
  useEffect(()=>{
    if (paymentIntent) {
        closeSpinner()
        openModal("reservation-payment")
      }
  },[paymentIntent,prepayRequest])  

  useEffect(() => {
    if (driver, endDate, upcomingSchedule) {
      setReadyForReservations(true)
    }
	}, [driver, endDate, upcomingSchedule])

  return driver ?
      (<CardTable>
        <div className="flex-row">              
          <div>
            Hello, {driver.given_name} you have {driverReservedHours} hours reserved {driverPendingHours} pending <br/>
            for the upcoming week
          </div>  
        </div>   
        <div className="flex-row car-chooser">
            <Select
              label="car"
              onChange={(car)=>{
                
                setChosenCar(cars[parseInt(car)])
              }}
              options={cars.map((car) => {
                return {
                  label:`${car.make} ${car.model} ${car.plate}`,
                  value: cars.indexOf(car).toString()
                }
              })}
              value={chosenCar ? cars.indexOf(chosenCar).toString() : ""}></Select>             
        </div>      
        <div className="flex-row day-chooser">
           <Select
              label="day"
              onChange={(day)=>setDay(day)}
              options={[
                {
                  label:MONDAY,
                  value:MONDAY
                },
                {
                  label:TUESDAY,
                  value:TUESDAY
                },
                {
                  label:WEDNESDAY,
                  value:WEDNESDAY
                },
                {
                  label:THURSDAY,
                  value:THURSDAY
                },
                {
                  label:FRIDAY,
                  value:FRIDAY
                },
                {
                  label:SATURDAY,
                  value:SATURDAY
                },
                {
                  label:SUNDAY,
                  value:SUNDAY
                }]}
              value={day}></Select>   
        </div>       
         <div className="flex-row shift-chooser">
           <Select
              label="shift"
              onChange={(shift)=>setShift(shift)}
              options={[
                {
                  label:MORNING, 
                  value:MORNING, 
                  disabled:carReservations.filter(res=>res.shift === Shift.MORNING && res.day === day.toUpperCase()).length > 0
                },
                {
                  label:AFTERNOON,
                  value:AFTERNOON, 
                  disabled:carReservations.filter(res=>res.shift === Shift.AFTERNOON && res.day === day.toUpperCase()).length > 0
                },
                {
                  label:EVENING,
                  value:EVENING, 
                  disabled:carReservations.filter(res=>res.shift === Shift.EVENING && res.day === day.toUpperCase()).length > 0
                },
                {
                  label:NIGHT,
                  value:NIGHT, 
                  disabled:carReservations.filter(res=>res.shift === Shift.NIGHT && res.day === day.toUpperCase()).length > 0
                }]}
              value={shift}></Select>                    
        </div>                
        <div className="flex-row-column add-shift">
          <Button
            name="add-reservation"
            label="Add Shift"
            onClick={async(event)=> {

              const chosen_day = new Date()
              
              const chosen_buf = daily.indexOf(day) > 0 ?
                ((7 - upcomingSchedule.getDay()) % 7 + daily.indexOf(day)) % 7 :
                6
              
              chosen_day.setDate(upcomingSchedule.getDate() + chosen_buf);

              const exists = carReservations.filter(res=>(
                res.date === chosen_day.toISOString().split('T')[0] &&
                res.shift === shift.toUpperCase() &&
                res.day === day.toUpperCase()
              ))

              if (exists.length) {
                console.warn("reservation already exists")
                return
              }

              const reserve = {
                  date: chosen_day.toISOString().split('T')[0],
                  shift: shift.toUpperCase(),
                  day: day.toUpperCase(),
                  driverID: driver.id,
                  carID: chosenCar.id,
                  status: Reservationstatus.PENDING
                }
              
              
              await DataStore.save(
                new Reservation(reserve)
              )                
            }} />                      
        </div>
        <div>
          <hr/>
        </div>
        <div className="flex-row-split">          
          {
            (driverHasReserved && driverReservedHours > 20)  ?
              ("< 20 hours pending ") :
              (<Select
                 label="Prepay Mileage"
                 onChange={(prepayment)=>setPrepayRequest(prepayment)}
                 options={prepaymentOptions.map(
                     option => { 
                       return {
                         label: `${option.miles} miles @ ${new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(option.rate/100)}`, 
                         value: option.miles.toString()
                       } 
                     }
                   )}
                 value={prepayRequest.toString()}></Select>)             
          }    
           | 
          {
            driverPendingHours ?                    
              (<Button
                name="checkout"
                label={`Reserve ${driverPendingHours} hr`}
                onClick={onCheckout} />) :
              (" add a reservation get started")
          }
        </div>       
        <ModalContent identifier="reservation-payment">
        {
          (paymentIntent && isPaying === false) ?
            (
              <form>
                <div className="payment-form">
                  <div className="invoice-line flex-row-between">
                    <span>Reserved Hours: {
                      paymentIntent.reserved_hours} @ {`${new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(1.25)}`
                    }
                    </span>
                    <span>{`${new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(paymentIntent.reserved_hours*1.25)}`}</span>
                  </div>
                  {
                    (paymentIntent.mileage > paymentIntent.prepaid_miles_applied) ? 
                      (                    
                        <div className="invoice-line indent-start flex-row-between">
                          <span>Miles Driven: {paymentIntent.mileage}</span>
                          <span></span>
                        </div>
                      ) :
                      <div/>
                  }                              
                  {
                    (paymentIntent.mileage > paymentIntent.prepaid_miles_applied) ? 
                      (                                
                        <div className="invoice-line indent-start flex-row-between">
                          <span>Mileage Reserve: {paymentIntent.prepaid_miles_applied}</span>
                          <span></span>
                        </div>
                      ) :
                      <div/>
                  }                       
                  <div className="invoice-line flex-row-between">
                    <span>Unpaid Miles: {
                      (paymentIntent.mileage-paymentIntent.prepaid_miles_applied > 0) ?
                        (paymentIntent.mileage-paymentIntent.prepaid_miles_applied) :
                        0
                    } @ {
                      `${new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(.16)}`
                    }
                    
                    </span>
                    <span>
                    {`${new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(
                      ((paymentIntent.mileage-paymentIntent.prepaid_miles_applied > 0) ?
                        (paymentIntent.mileage-paymentIntent.prepaid_miles_applied) :
                        0) * .16
                      )}`
                    }                       
                    </span>
                  </div>    
                  {
                    (prepayRequest) ? 
                      (
                        <div className="invoice-line flex-row-between">
                          <span>Prepay Miles: {
                            PREPAYMENT[prepayRequest].miles
                          } @ {
                            new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(PREPAYMENT[prepayRequest].rate/100)
                          }
                          </span>
                          <span>
                          {`${new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(PREPAYMENT[prepayRequest].miles*PREPAYMENT[prepayRequest].rate/100)}`}
                          </span>
                        </div>
                      ) :
                      <div/>
                  }   
                  {
                    (paymentIntent.penalties) ? 
                      (
                        <div className="invoice-line flex-row-between">
                          <span>Penalty ({paymentIntent.penalties})</span>
                          <span>{
                            new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(paymentIntent.penalty_fee/100)
                          }</span>
                        </div>
                      ) :                        
                      <div/>
                  }          
                  <div className="invoice-line flex-row-between">
                    <span>Sales Tax</span>
                    <span>
                    {`${new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(paymentIntent.columbus_sales_tax/100)}`
                    }                       
                    </span>                      
                  </div>
                    
                  <Button
                    name="checkout"
                    
                    label={`Reserve now ${new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(paymentIntent.total_fee/100)}`}
                    onClick={(e)=>{
                      e.preventDefault()
                      window.localStorage.setItem('pending', pendingReservations.length)
                      setIsPaying(true)}
                    } />                
                </div>         
              </form>
            ) :
            paymentIntent && isPaying ? 
            (
              <Elements options={
                {
                  clientSecret: paymentIntent.client_secret,
                  appearance: {theme: 'stripe'}
                }
              } stripe={stripePromise}>
                <CheckoutForm 
                  carpeIntent={paymentIntent} 
                  driverID={driver.id} 
                  driverPending={pendingReservations}
                  driverPaid={paidReservations}
                  cars={cars} />
              </Elements>
            ) :
            <div/>
        }            
        </ModalContent>
        <div className="flex-row car-chooser">
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
        </div>    
      </CardTable>):
      (<div>loading</div>)
}

export default ReservationScheduler
