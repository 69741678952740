import React, { useState, useEffect } from 'react';
import { DataStore } from '@aws-amplify/datastore';
import { Car } from '../models';

export default () => {
  const [upcomingSchedule, setUpcomingSchedule] = useState()
  const [endDate, setEndDate] = useState()


  useEffect(() => {
    const today = new Date();
    const upcoming_schedule = new Date()

    upcoming_schedule.setDate(today.getDate() + ((7 - today.getDay()) % 7) % 7+1)

    const end_date = new Date()
    end_date.setDate(upcoming_schedule.getDate()+6) 

    setUpcomingSchedule(upcoming_schedule)
    setEndDate(end_date)                    
  }, []) 


  return {upcomingSchedule,endDate};
}