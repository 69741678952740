import React from 'react'
import {
  Link
} from "react-router-dom";
const CarpeMenu = ({isDriver, isManager, driverData}) => {
        return (
        <nav className="carpe-menu">
          <ul>
          {
              (isDriver && driverData && driverData.status === "ACTIVE") ? (
                <li><Link to="/schedule-reservations">Schedule</Link></li>
              ) : ''
          }
          {
              isDriver ? (
                <li><Link to="/profile">Profile</Link></li>
              ) : ''
          }
          {
              isManager ? (
                <li><Link to="/add-car">Add Car</Link></li>
              ) : ''
          }  
          {
              isManager ? (
                <li><Link to="/assign-driver">Assign Driver</Link></li>            
              ) : ''
          }            
          </ul>
        </nav>)
}

export default CarpeMenu