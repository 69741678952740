import React, { useEffect, useState, useCallback } from "react";
import {
	closeModal
} from "@paytheory/pay-theory-ui";
import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";


import { API } from "aws-amplify";

const CheckoutForm = ({carpeIntent, driverID, driverPending, driverPaid, cars, calendarCallback, successCallback}) => {
  const stripe = useStripe();
  const elements = useElements();

  
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isPaid,setIsPaid] = useState(false)

  useEffect(()=>{
    if (isPaid) {
      calendarCallback(cars)
    }
  },[isPaid])

  const handleSubmit = useCallback(async(e)=>{
    
      e.preventDefault()
      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }

      setIsLoading(true);

      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: window.location.href
        }
      });

      // // This point will only be reached if there is an immediate error when
      // // confirming the payment. Otherwise, your customer will be redirected to
      // // your `return_url`. For some payment methods like iDEAL, your customer will
      // // be redirected to an intermediate site first to authorize the payment, then
      // // redirected to the `return_url`.
      if (error.type === "card_error" || error.type === "validation_error") {
        setMessage(error.message);
      } else {
        setMessage("An unexpected error occured.");
      }

      setIsLoading(false);        
      
      
  },[carpeIntent, stripe, elements, driverID, driverPending]);
	
  return (        	
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" />
      <button disabled={isLoading || !stripe || !elements} id="submit">
        <span id="button-text">
          {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
        </span>
      </button>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form> )
}

export default CheckoutForm
