export const formatPhone = (incoming) => {
    incoming = /* istanbul ignore next */ incoming || ''
    incoming = incoming.replace(/[\D]/g, '')
    const areaCode = incoming.substring(0, 3)
    const prefix = incoming.substring(3, 6)
    const line = incoming.substring(6, 10)
    const phone = /* istanbul ignore next */ line
        ? `${areaCode}-${prefix}-${line}`
        : /* istanbul ignore next */
        prefix
        ? `${areaCode}-${prefix}`
        : areaCode

    return phone
}