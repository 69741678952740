import { useState } from 'react'
import {
    CardTable,
    Select,
    Button,
    openSpinner
}
from "@paytheory/pay-theory-ui";

import { DataStore } from '@aws-amplify/datastore';
import { Driver, Driverstatus, Car, Carstatus, DriverCar } from '../models';

const AssignDriver = ({pendingDrivers,cars}) => {
  	const [chosenCar, setChosenCar] = useState()
  	const [chosenDriver, setChosenDriver] = useState()

        return (        	
        <CardTable>          	
            <div className="flex-row">
              <Select
                label="car"
                onChange={(chosen)=>setChosenCar(chosen)}
                options={cars.map((car) => {
                  return {
                    label:`${car.make} ${car.model} ${car.plate}`,
                    value:car
                  }
                })}
                value={chosenCar ? chosenCar : ""}></Select>           	
            </div>
            <div className="flex-row">
              <Select
                label="driver"
                onChange={(chosen)=>setChosenDriver(chosen)}
                options={pendingDrivers.map((driver) => {
                  return {
                    label:`${driver.family_name}, ${driver.given_name}`,
                    value:driver
                  }
                })}
                value={chosenDriver ? chosenDriver : ""}></Select>           	
            </div>
          	
            <div className="flex-row">
	            <Button
	              name="assign-driver"
	              label="Assign Driver"
	              onClick={async(event)=> {
                  
                  openSpinner()
		              await DataStore.save(
									  new DriverCar({
									    carID: chosenCar.id,
									    driverID: chosenDriver.id
									  })
									);
									
                  
                  /* Models in DataStore are immutable. To update a record you must use the copyOf function
									 to apply updates to the item’s fields rather than mutating the instance directly */
									await DataStore.save(Driver.copyOf(chosenDriver, item => {
									    item.status = Driverstatus.ACTIVE
									}));
									await DataStore.save(Car.copyOf(chosenCar, item => {
									    item.status = Carstatus.ONLINE
									}));		

	            }} />     
	          </div>         	          	 
        </CardTable>)
}

export default AssignDriver
