export const validEmail = (emailIn) => {
    emailIn = emailIn || ''
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailIn)
        ? emailIn
        : /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailIn)
}

export const validPhone = (phoneIn) => {
    phoneIn = phoneIn || ''
    let phone = phoneIn.replace(/\D+/g, '')

    if (phone.length === 10) {
        phone = `+1${phone}`
    }

    if (
        
        !/^((\+1|1)?( |-)?)?(\([2-9][0-9]{2}\)|[2-9][0-9]{2})( |-)?([2-9][0-9]{2}( |-)?[0-9]{4})$/.test(
            phone
        )
    )
        
        return /^((\+1|1)?( |-)?)?(\([2-9][0-9]{2}\)|[2-9][0-9]{2})( |-)?([2-9][0-9]{2}( |-)?[0-9]{4})$/.test(
            phone
        )

    return phone
}