import React, { useState, useEffect } from 'react';
import { DataStore } from '@aws-amplify/datastore';
import { Car } from '../models';

export default (isManager) => {
  const [availableCars, setAvailableCars] = useState([])


  useEffect(() => {
        // collect manager data for vehicleManagers
    if (isManager) {
      let subscription = DataStore.observeQuery(
          Car
        ).subscribe(snapshot => {
          const { items } = snapshot; 
          if (items.length) {  
            setAvailableCars(items)
            console.log("AvailableCars acquired",items)
          } 
        })      
      console.log("AvailableCars subscribed")
      return () => {        
        subscription.unsubscribe()
        console.log("AvailableCars unsubscribed")
      }
    }                       
  }, [isManager]) 


  return availableCars;
}