import React, { useState, useEffect } from 'react';
const CALENDAR_SCOPE = "https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/calendar.readonly https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/calendar"
const DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"]
export default () => {
    
  const [googleReady, setGoogleReady] = useState(false)
  const [googleAuth, setGoogleAuth] = useState()
  const [isAuthorized, setIsAuthorized] = useState(false)
  useEffect(() => {

		const handleClientLoad = () => window.gapi.load('client:auth2', initClient);
		const updateSigninStatus = (isSignedIn) => {
			setIsAuthorized(isSignedIn)	
			setGoogleReady(isSignedIn)
			console.log('useGoogle','updateSigninStatus',isSignedIn)
		} 
		const initClient = () => {
			console.log('useGoogle','initClient')
			window.gapi.client.init({
				apiKey: process.env.REACT_APP_GOOGLE_CALENDAR_API_KEY,
				clientId: process.env.REACT_APP_GOOGLE_CALENDAR_CLIENT_ID,
				discoveryDocs: DISCOVERY_DOCS,
				scope: CALENDAR_SCOPE
			}).then(function () {
				console.log('useGoogle','initClient','then',window.gapi.client.calendar)
			  // Listen for sign-in state changes.
			  const auth = window.gapi.auth2.getAuthInstance()
				auth.isSignedIn.listen(updateSigninStatus);
			  setGoogleAuth(auth)
			  
			  // Handle the initial sign-in state.
			  updateSigninStatus(auth.isSignedIn.get());
			  
			}, function(error) {
			  console.log('useGoogle','error',JSON.stringify(error, null, 2));
			  setGoogleReady(false)
			});
		}	


	      
	  

		const script = document.createElement('script');

		script.src = "https://apis.google.com/js/api.js";
		script.async = true;
		script.defer = true;
		script.onload = handleClientLoad;

		document.body.appendChild(script);      

		return () => {
		  document.body.removeChild(script);
		  setGoogleReady(false)
		  console.log("Google unloaded");
		};
  }, [])


  return { googleReady, googleAuth, isAuthorized};
}