import { useState } from 'react'
import {
    CardTable,
    TextEntry,
    TextEntryDate,
    Button
}
from "@paytheory/pay-theory-ui";
import { validPhone, validEmail } from "../util/valid"
import { formatPhone } from "../util/format"

import { DataStore } from '@aws-amplify/datastore';
import { Driver, Driverstatus } from '../models';

const DriverProfile = ({cognitoUser}) => {
	const [givenName, setGivenName] = useState("")
	const [familyName, setFamilyName] = useState("")
	const [birthDate, setBirthDate] = useState("")
	const [driversLicense, setDriversLicense] = useState("")
	const [email, setEmail] = useState("")
	const [phone, setPhone] = useState("")

        return (        	
        <CardTable>
          	<div className="flex-row">
            	<TextEntry 
            		name="given-name" 
            		label="First Name" 
            		value={givenName} 
            		onChange={(e)=>setGivenName(e.target.value)}/>
          	</div>
          	<div className="flex-row">          	
            	<TextEntry 
            		name="family-name" 
            		label="Last Name" 
            		value={familyName} 
            		onChange={(e)=>setFamilyName(e.target.value)}/>          	
          	</div>
          	<div className="flex-row">
            	<TextEntryDate 
		            helperText="MM/DD/YYYY"
            		name="birth-date" 
            		label="Birth Date" 
            		value={birthDate} 
            		onChange={(bd)=>setBirthDate(bd)} />
            </div>
          	<div className="flex-row">          	
            	<TextEntry 
            		name="drivers-license" 
            		label="Drivers License Number" 
            		value={driversLicense} 
            		onChange={(e)=>setDriversLicense(e.target.value)}/>          	
          	</div>          	
          	<div className="flex-row">          	
		        <TextEntry
            		name="email" 
            		label="Email" 
		            isValid={(() => validEmail(email.replace(/\D/, '')))()}
		            value={email}
		            onChange={(e) => setEmail(e.target.value)}
		        />            		
          	</div>       	
          	<div className="flex-row">          	
		        <TextEntry
		            helperText="123-456-7890"
            		name="phone" 
            		label="Phone" 
		            isValid={(() => validPhone(phone.replace(/\D/, '')))()}
		            value={phone}
		            onChange={(e) => {		                
		                setPhone(formatPhone(e.target.value))
		            }}
		        />            		
          	</div>
          	
            <Button
              name="add-driver"
              label="Submit Profile"
              onClick={async(event)=> {

                const driver = {
                    given_name: givenName,
                    family_name: familyName,
                    birth_date: birthDate.replace(/(\d\d).(\d\d).(\d\d\d\d)/,"$3-$1-$2"),
                    drivers_license: driversLicense,
                    status:Driverstatus.PENDING,
                    email:email,
                    phone:phone,
                    cognito_id:cognitoUser
                  }
                
                await DataStore.save(
                  new Driver(driver)
                )                
              }} />            	          	 
        </CardTable>)
}

export default DriverProfile
