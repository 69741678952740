import React, { useState, useEffect } from 'react';
import { DataStore } from '@aws-amplify/datastore';
import { Reservation,Reservationstatus } from '../models';

export default (chosenCar,endDate,upcomingSchedule) => {
  const [carReservations, setCarReservations] = useState([])

  useEffect(() => {
    let subscription
    
    if (chosenCar) {        
      subscription = DataStore.observeQuery(
        Reservation,
        r => ( r.carID === chosenCar.id && r.date >= upcomingSchedule.toISOString().split('T')[0]))
      .subscribe(snapshot => {
        let { items } = snapshot       
        items = items.filter( r => r.date >= upcomingSchedule.toISOString().split('T')[0] )
          .filter( r => r.date <= endDate.toISOString().split('T')[0] )
          .filter( r => r.status!==Reservationstatus.CANCELLED)
        setCarReservations(items)  
      }) 
      return ()=>subscription.unsubscribe()
    }           
  }, [chosenCar, endDate, upcomingSchedule])


  return carReservations;
}