import './App.css';
import CarpePage from "./components/CarpePage"
import { DataStore } from '@aws-amplify/datastore';

import { Amplify } from 'aws-amplify';

import React from 'react';
import { Authenticator } from '@aws-amplify/ui-react';

import awsExports from './aws-exports';
Amplify.configure(awsExports);

(async() => {
  await DataStore.stop()
  await DataStore.clear()
  await DataStore.start()
})()

// window.LOG_LEVEL = 'DEBUG'



const App = () => {
  // const [user, setUser] = useState()  
  // const [pendingDrivers, setPendingDrivers] = useState([])
  // const [availableCars, setAvailableCars] = useState([])
  // const [driverCars, setDriverCars] = useState([])
  // const [userName, setUserName] = useState()
  // const [driverData, setDriverData] = useState()
  
  // const [googleReady, setGoogleReady] = useState()
  // const [googleAuth, setGoogleAuth] = useState()
  // const [isAuthorized, setIsAuthorized] = useState()

  // const [assignDriver,setAssignDriver] = useState(false)
  // const [createCar,setCreateCar] = useState(false)
  // const [reservationScheduler,setReservationScheduler] = useState(false)

  // const [hasDriverData,setHasDriverData] = useState(false)

  // const [payment,setPayment] = useState(false)
  // const [clientSecret] = useState(client_secret)
  
  // useEffect(() => {
    
  //   if (!payment) {
  //     return;
  //   }

  //   if (!clientSecret) {
  //     return;
  //   }
  //   (async()=>{
  //     const stripe = await stripePromise
  
  //     stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
  //       switch (paymentIntent.status) {
  //         case "succeeded":
  //           console.log("Payment succeeded!");
  //           break;
  //         case "processing":
  //           console.log("Your payment is processing.");
  //           break;
  //         case "requires_payment_method":
  //           console.log("Your payment was not successful, please try again.");
  //           break;
  //         default:
  //           console.log("Something went wrong.");
  //           break;
  //       }
  //     });      
  //   })()
    
  // }, [payment,clientSecret]);

  // // init google
  // useEffect(() => {
  //     const handleClientLoad = () => window.gapi.load('client:auth2', initClient);
  
  //     const updateSigninStatus = (isSignedIn) => {
  //       if (isSignedIn) {
  //         setIsAuthorized(true)
  //       } else {
  //         setIsAuthorized(false)
  //       }
  //     } 

  //     const initClient = () => {          
  //         window.gapi.client.init({
  //           apiKey: process.env.REACT_APP_GOOGLE_CALENDAR_API_KEY,
  //           clientId: process.env.REACT_APP_GOOGLE_CALENDAR_CLIENT_ID,
  //           scope: CALENDAR_SCOPE,
  //           discoveryDocs: ["https://www.googleapis.com/discovery/v1/apis/drive/v3/rest"]
  //         }).then(()=>{
  //           const GoogleAuth = window.gapi.auth2.getAuthInstance()

  //           // Listen for sign-in state changes.
  //           GoogleAuth.isSignedIn.listen(updateSigninStatus)
  //           setGoogleAuth(GoogleAuth)
  //         });
  //         console.log("Google loaded");
  //     };

  //     const script = document.createElement('script');

  //     script.src = "https://apis.google.com/js/api.js";
  //     script.async = true;
  //     script.defer = true;
  //     script.onload = handleClientLoad;

  //     document.body.appendChild(script);

  //     return () => {
  //         document.body.removeChild(script);
  //     };

  // }, [])
  
  // // init cognito authenticated
  // useEffect(() => {
  //   if (user && user.signInUserSession) {
  //     const grouped = user.signInUserSession.accessToken.payload["cognito:groups"]
  //     setIsDriver(grouped.indexOf("drivers") > -1)
  //     setIsManager(grouped.indexOf("vehicleManagers") > -1)    
  //   }
  // }, [user])   

  // // driver data subscription
  // useEffect(() => {      
  //   // collect driver data for drivers
  //   if (isDriver && userName) {
  //     let subscription = DataStore.observeQuery(
  //       Driver, 
  //       d => d.cognito_id("eq", userName)
  //     ).subscribe( async snapshot => {
  //       const { items } = snapshot; 
  //       if (items.length) {  
          
  //         const driver_data = items[0]
  //         const driver_cars = (await DataStore.query(DriverCar))
  //           .filter(cd => cd.driver.id === driver_data.id)
  //           .map(cd => cd.car)            
          
  //         setDriverCars(driver_cars)            
  //         setDriverData(driver_data)
  //         setHasDriverData(true)   
  //         console.log("driver data acquired",driver_data,driver_cars)

  //         closeSpinner()      
  //       } 
  //     })
  //     console.log("driver data subscribed")
  //     return () => {        
  //       subscription.unsubscribe()
  //       console.log("driver data unsubscribed")
  //     }
  //   }
    
  // }, [userName, isDriver])    

  // // pending drivers subscription
  // useEffect(() => {
  //       // collect manager data for vehicleManagers
  //   if (isManager) {
  //     let subscription = DataStore.observeQuery(
  //         Driver, 
  //         d => d.status("eq", Driverstatus.PENDING)
  //       ).subscribe(snapshot => {
  //         const { items } = snapshot; 
  //         if (items.length) {  
  //           setPendingDrivers(items)
  //           console.log("pendingDrivers acquired",items)
  //         } 
  //       })
  //     console.log("pendingDrivers subscribed")
  //     return () => {        
  //       subscription.unsubscribe()
  //       console.log("pendingDrivers unsubscribed")
  //     }
  //   }                       
  // }, [isManager])   

  // // available cars subscription
  // useEffect(() => {
  //       // collect manager data for vehicleManagers
  //   if (isManager) {
  //     let subscription = DataStore.observeQuery(
  //         Car
  //       ).subscribe(snapshot => {
  //         const { items } = snapshot; 
  //         if (items.length) {  
  //           setAvailableCars(items)
  //           console.log("AvailableCars acquired",items)
  //           closeSpinner()
  //         } 
  //       })      
  //     console.log("AvailableCars subscribed")
  //     return () => {        
  //       subscription.unsubscribe()
  //       console.log("AvailableCars unsubscribed")
  //     }
  //   }                       
  // }, [isManager])   

  return (
    <Authenticator>
      {({ signOut, user }) => (
        <CarpePage user={user} />
      )}
      </Authenticator>    
        
    )

}

export default App;
