import { useState } from 'react'
import {
    CardTable,
    TextEntry,
    TextEntryDate,
    Select,
    Button,
    openSpinner
}
from "@paytheory/pay-theory-ui";


import { DataStore } from '@aws-amplify/datastore';
import { Car, Carstatus, Carclass } from '../models';

const CreateCar = ({cognitoUser}) => {
	const [make, setMake] = useState("")
	const [model, setModel] = useState("")
	const [vehicleClass, setVehicleClass] = useState("")
	const [vin, setVin] = useState("")
	const [year, setYear] = useState("")
	const [registeredThrough, setRegisteredThrough] = useState("")
	const [plate, setPlate] = useState("")

        return (        	
        <CardTable>
          	<div className="flex-row">
            	<TextEntry 
            		name="make" 
            		label="Make" 
            		value={make} 
            		onChange={(e)=>setMake(e.target.value)}/>
          	</div>
          	<div className="flex-row">          	
            	<TextEntry 
            		name="model" 
            		label="Model" 
            		value={model} 
            		onChange={(e)=>setModel(e.target.value)}/>          	
          	</div>
          	<div className="flex-row">          	
            	<TextEntry 
            		name="year" 
            		label="Year" 
            		value={year} 
            		onChange={(e)=>setYear(e.target.value)}/>          	
          	</div>   
            <div className="flex-row">
              <Select
                label="Vehicle Class"
                onChange={(chosen)=>setVehicleClass(chosen)}
                options={[
                	{ label:"Compact", value:Carclass.COMPACT },
									{ label:"Standard", value:Carclass.STANDARD },
									{ label:"Extra", value:Carclass.EXTRA },
									{ label:"Delivery", value:Carclass.DELIVERY }
                ]}
                value={vehicleClass ? vehicleClass : ""}></Select>           	
            </div>
          	<div className="flex-row">
            	<TextEntry 
            		name="vin" 
            		label="VIN" 
            		value={vin} 
            		onChange={(e)=>setVin(e.target.value)}/>          	
          	</div>   
          	<div className="flex-row">
            	<TextEntry 
            		name="plate" 
            		label="License Plate" 
            		value={plate} 
            		onChange={(e)=>setPlate(e.target.value)}/>          	
          	</div>   
          	<div className="flex-row">
            	<TextEntryDate 
		            helperText="MM/DD/YYYY"
            		name="registered-through" 
            		label="Registered Through Date" 
            		value={registeredThrough} 
            		onChange={(bd)=>setRegisteredThrough(bd)} />
            </div>     
            <div className="flex-row">
	            <Button
	              name="add-car"
	              label="Submit Car"
	              onClick={async(event)=> {

	                const car = {
	                    make: make,
	                    model: model,
	                    year: parseInt(year),
	                    carclass: vehicleClass,
	                    vin:vin,
	                    plate:plate,
	                    registered_through: registeredThrough.replace(/(\d\d).(\d\d).(\d\d\d\d)/,"$3-$1-$2"),
	                    status: Carstatus.PENDING,                   
	                  }
	                openSpinner()
	                await DataStore.save(
	                  new Car(car)
	                )                
	              }} />     
	          </div>         	          	 
        </CardTable>)
}

export default CreateCar
