import React, { useState, useEffect } from 'react';
import { DataStore } from '@aws-amplify/datastore';
import { Reservation, Reservationstatus } from '../models';

export default (driver, endDate, upcomingSchedule, readyForReservations) => {
  const [pendingReservations, setPendingReservations] = useState([])
  const [paidReservations, setPaidReservations] = useState([])
  const [unpaidMiles, setUnpaidMiles] = useState([])

  useEffect(() => {
    if (readyForReservations && driver) {
      console.log('subscribe pending reservations')
      const subscription = DataStore.observeQuery(
        Reservation
      ).subscribe(snapshot => {
        let { items } = snapshot;   
        
        const pending = items.filter(r => r.driverID === driver.id)
          .filter( r => r.date >= upcomingSchedule.toISOString().split('T')[0] )
          .filter( r => r.date <= endDate.toISOString().split('T')[0] )
          .filter( r => r.status===Reservationstatus.PENDING )  
        
        const reserved = items.filter(r => r.driverID === driver.id)
          .filter( r => r.date >= upcomingSchedule.toISOString().split('T')[0] )
          .filter( r => r.date <= endDate.toISOString().split('T')[0] )
          .filter( r => r.status===Reservationstatus.RESERVATION_PAID )  
        
        const unpaid = items.filter(r => r.driverID === driver.id)
          .filter( r => r.date < upcomingSchedule.toISOString().split('T')[0] )          
          .filter( r => r.status===Reservationstatus.RESERVATION_PAID )            
            
             
   
        console.log('reservations',pending.length,reserved.length,unpaid.length)
        
        setPendingReservations(pending)
        setPaidReservations(reserved) 
        setUnpaidMiles(unpaid) 
      })              
      return ()=>subscription.unsubscribe()
    }        
  }, [driver, endDate, upcomingSchedule, readyForReservations]) 


  return { pendingReservations, paidReservations, unpaidMiles };
}