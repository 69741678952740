import CarpeHead from "./CarpeHead"
import DriverProfile from './DriverProfile';
import CreateCar from './CreateCar';
import AssignDriver from './AssignDriver';
import CarpeMenu from './CarpeMenu';
import ReservationScheduler from './ReservationScheduler';
import FourOhFour from './FourOhFour';
import { loadStripe } from "@stripe/stripe-js";
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import {
    CardTable,
    ModalSpinner,
    closeSpinner
}
from "@paytheory/pay-theory-ui";

import { DataStore } from '@aws-amplify/datastore';
import { Driver, Driverstatus, Car, DriverCar } from '../models';
import useDriverData from '../hooks/useDriverData'
import usePendingDrivers from '../hooks/usePendingDrivers'
import useAvailableCars from '../hooks/useAvailableCars'
import React, { useState, useEffect } from 'react';

const CALENDAR_SCOPE = "https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events"
const DISCOVERY_URL = "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest";

const userHasGroups = user => {
  return user.signInUserSession ?
    user.signInUserSession.accessToken.payload["cognito:groups"] :
    false
}

const params = new URLSearchParams(window.location.search)

const client_secret = params ? params.get("payment_intent_client_secret") : false

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

const CarpePage = ({user}) => {
	// derived from user
	const [isDriver,setIsDriver] = useState(false)
	const [isManager,setIsManager] = useState(false)

	
	const [payment,setPayment] = useState(false)	

	const driver = useDriverData(user.username,isDriver)
	const pendingDrivers = usePendingDrivers(isManager)
	const availableCars = useAvailableCars(isManager)

	console.log("driver",driver, isDriver)

	useEffect(() => {
	    if (user && user.signInUserSession) {
	    	const grouped = user.signInUserSession.accessToken.payload["cognito:groups"]	    	
	    	setIsDriver(grouped.indexOf("drivers") > -1)
	    	setIsManager(grouped.indexOf("vehicleManagers") > -1)
	    }		
	}, [user]) 

		
    return (        	
        <div id="container" className="App">
			<BrowserRouter>
				<CarpeHead />
				<CarpeMenu isDriver={isDriver} isManager={isManager} driverData={driver.data} />
				<div>{payment ? payment.status : ''}</div>
				<CardTable>
				  <Routes>                
				    <Route 
				    	path="/" 
				    	element={<FourOhFour />} />
	                <Route 
	                	path="/profile" element={<DriverProfile cognitoUser={user.username}/>} />	                
	                <Route 
	                	path="/add-car" 
	                	element={<CreateCar cars={availableCars} pendingDrivers={pendingDrivers} />} />
	                <Route 
	                	path="/assign-driver" 
	                	element={<AssignDriver cars={availableCars} pendingDrivers={pendingDrivers} />} />
	                <Route 
	                	path="/schedule-reservations" 
	                	element={<ReservationScheduler driver={driver.data} cars={driver.cars} successCallback={paymentIntent => setPayment(paymentIntent)} />} />
				  </Routes>
				</CardTable>
				<ModalSpinner />
			</BrowserRouter>	        
	    </div> )
}

export default CarpePage
