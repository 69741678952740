// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const Reservationpenalty = {
  "LATEPICKUP": "LATEPICKUP",
  "LATEDROPOFF": "LATEDROPOFF",
  "NOSHOW": "NOSHOW",
  "EXTERIORCONDITION": "EXTERIORCONDITION",
  "INTERIORCONDITION": "INTERIORCONDITION"
};

const Reservationstatus = {
  "PENDING": "PENDING",
  "RESERVATION_PAID": "RESERVATION_PAID",
  "FEES_PAID": "FEES_PAID",
  "CANCELLED": "CANCELLED"
};

const Shift = {
  "MORNING": "MORNING",
  "AFTERNOON": "AFTERNOON",
  "EVENING": "EVENING",
  "NIGHT": "NIGHT"
};

const Day = {
  "MONDAY": "MONDAY",
  "TUESDAY": "TUESDAY",
  "WEDNESDAY": "WEDNESDAY",
  "THURSDAY": "THURSDAY",
  "FRIDAY": "FRIDAY",
  "SATURDAY": "SATURDAY",
  "SUNDAY": "SUNDAY"
};

const Externalprocessor = {
  "STRIPE": "STRIPE"
};

const Driverstatus = {
  "PENDING": "PENDING",
  "ACTIVE": "ACTIVE",
  "SUSPENDED": "SUSPENDED"
};

const Carclass = {
  "COMPACT": "COMPACT",
  "STANDARD": "STANDARD",
  "EXTRA": "EXTRA",
  "DELIVERY": "DELIVERY"
};

const Carstatus = {
  "PENDING": "PENDING",
  "ONLINE": "ONLINE",
  "OFFLINE": "OFFLINE"
};

const { Payment, Reservation, Driver, Car, DriverCar } = initSchema(schema);

export {
  Payment,
  Reservation,
  Driver,
  Car,
  DriverCar,
  Reservationpenalty,
  Reservationstatus,
  Shift,
  Day,
  Externalprocessor,
  Driverstatus,
  Carclass,
  Carstatus
};